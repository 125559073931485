var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-tour", {
    attrs: { name: "vuexyTour", steps: _vm.steps },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(tour) {
          return [
            _c(
              "transition",
              { attrs: { name: "fade" } },
              _vm._l(tour.steps, function(step, index) {
                return tour.currentStep === index
                  ? _c(
                      "v-step",
                      {
                        key: index,
                        attrs: {
                          step: step,
                          "previous-step": tour.previousStep,
                          "next-step": tour.nextStep,
                          stop: tour.stop,
                          "is-first": tour.isFirst,
                          "is-last": tour.isLast,
                          labels: tour.labels
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "tour-actions d-flex justify-content-between",
                            attrs: { slot: "actions" },
                            slot: "actions"
                          },
                          [
                            tour.currentStep != tour.steps.length - 1
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-tour-skip mr-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "outline-primary"
                                    },
                                    on: { click: tour.stop }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mr-25 align-middle" },
                                      [_vm._v("Skip")]
                                    ),
                                    _c("feather-icon", {
                                      attrs: { icon: "XIcon", size: "12" }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            tour.currentStep
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      size: "sm",
                                      variant: "outline-primary mr-1"
                                    },
                                    on: { click: tour.previousStep }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "ChevronLeftIcon",
                                        size: "12"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "ml-25 align-middle" },
                                      [_vm._v("Previous")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            tour.currentStep != tour.steps.length - 1
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-tour-next",
                                    attrs: { size: "sm", variant: "primary" },
                                    on: { click: tour.nextStep }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mr-25 align-middle" },
                                      [_vm._v("Next")]
                                    ),
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "ChevronRightIcon",
                                        size: "12"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            tour.currentStep == tour.steps.length - 1
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-tour-finish",
                                    attrs: { size: "sm", variant: "primary" },
                                    on: { click: tour.stop }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mr-25 align-middle" },
                                      [_vm._v("Finish")]
                                    ),
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "CheckCircleIcon",
                                        size: "12"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              }),
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }